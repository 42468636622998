import { Csku, ErrorBoundary } from "@commonsku/styles";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPopups } from "../../selectors";
import createPopup from "../../popup-factory";
import NoMatch from "../NoMatch";
import { closePopup, fetchNotes, getClientDetailsState } from "../../redux/clientDetails";
import { ClientSidebar } from "./ClientSidebar";
import Header from "../../containers/Header";
import MainSection from "../../containers/MainSection";
import { OverviewPage } from "./Overview/OverviewPage";
import styled from "styled-components";
import { popups } from "./types";
import { toast, ToastContainer } from "react-toastify";
import { oauth } from "../../utils";
import { ContactsPage } from "./Contacts/ContactsPage";
import { AddressesPage } from "./Addresses/AddressesPage";
import { ArtworkLibraryProvider } from "../artwork-library/context";
import ArtworkLibrary from "../artwork-library/ArtworkLibrary";
import { ActivityPage } from "./Activity/ActivityPage";
import useClientDetails from "./hooks/useClientDetails";
import { ProjectsPage } from "./Projects/ProjectsPage";
import { useIdentity } from "../../hooks";

const REFETCH_NOTES_INTERVAL = 30 * 1000;
const MINIMUM_NOTES_TO_FETCH = 4;

const Home = styled.div`
    &&& {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 32px;
        padding-top: calc(32px - 1rem);
        padding-left: calc(32px - 1rem);
    }
`;

export const ClientHome = () => {
    const basePopups = useSelector(getPopups);
    const { client, contacts } = useClientDetails();
    const { notes, currentPopup } = useSelector(getClientDetailsState);
    const dispatch = useDispatch();
    const { user_id } = useIdentity();

    useEffect(() => {
        document.title = client.name;
    }, [client.name]);

    useEffect(() => {
        const fetch = () => {
            dispatch(fetchNotes({
                user_id,
                client_id: client.id,
                minimumNotes: MINIMUM_NOTES_TO_FETCH,
                getNewNotes: true,
            }));
        };

        fetch();
        const timerId = setInterval(fetch, REFETCH_NOTES_INTERVAL);
        return () => clearInterval(timerId);
    }, []);

    const menu = <ClientSidebar />;

    const onDeleteClient = () => {
        oauth('DELETE', `account/${client.id}`, { company_type: "CLIENT" })
            .then(() => {
                toast.success('Client deleted');
                // Can't use react-router navigation outside of react
                setTimeout(() => window.location.href = '../clients.php', 500);
            })
            .catch(() => {
                toast.error('Error deleting client');
            });
    };

    const renderPopup = () => {
        if (!currentPopup) return null;

        const popupProps = {
            onClosePopup: () => dispatch(closePopup()),
            onClose: () => dispatch(closePopup()),
            client_id: client.id,
            parent_id: client.id,
            parent_type: 'CLIENT',
        };

        const Popup = popups[currentPopup];

        switch (currentPopup) {
            case "delete-client":
                return (
                    <Popup
                        onClose={popupProps.onClose}
                        onDelete={onDeleteClient}
                    />
                );
            default:
                return <Popup {...popupProps} />;
        }
    };

    return (<>
        <Header menu={menu} />
        <MainSection menu={menu}>
            {renderPopup()}
            {basePopups ? basePopups.map((p, idx) => createPopup(p, idx)) : null}
            <Csku as={"div"} className="main-content">
                <Home>
                    <Routes>
                        <Route
                            index
                            element={
                                <OverviewPage
                                    client={client}
                                    contacts={contacts}
                                    recentActivity={notes ?? []}
                                />
                            }
                        />
                        <Route
                            path="/activity"
                            element={<ActivityPage />}
                        />
                        <Route
                            path="/contacts"
                            element={<ContactsPage />}
                        />
                        <Route
                            path="/addresses"
                            element={<AddressesPage />}
                        />
                        <Route
                            path="/library"
                            element={<ArtworkLibraryProvider
                                initParents={[
                                    {
                                        parent_type: 'CLIENT',
                                        parent_id: client.id,
                                    },
                                ]}
                                initParent={{
                                    parent_type: 'CLIENT',
                                    parent_id: client.id,
                                }}
                                isModal={false}
                                modalOpen={false}
                                setModalOpen={false}
                                onFileSelect={(f) => { }}
                            >
                                <ArtworkLibrary />
                            </ArtworkLibraryProvider>}
                        />
                        <Route
                            path="/projects"
                            element={<ProjectsPage />}
                        />
                        <Route
                            path={"*"}
                            element={<NoMatch location={window.location} />}
                            ErrorBoundary={ErrorBoundary}
                        />
                    </Routes>
                </Home>
                <ToastContainer
                    autoClose={3000}
                    hideProgressBar={true}
                />
            </Csku>
        </MainSection>
    </>);
};
