import styled from "styled-components";
import { SubPageHeader } from "../SubPageHeader";
import React, { useCallback, useEffect, useState } from "react";
import { ActionPanel } from "./ActionPanel";
import { colors, SkubotSpinner } from "@commonsku/styles";
import { useDispatch, useSelector } from "react-redux";
import { deleteNote, fetchNotes, getClientDetailsState, pinNote, setLoading } from "../../../redux/clientDetails";
import { DisplayNote, NoteType } from "../../../types";
import { NoteDetails } from "./NoteDetails";
import useClientDetails from "../hooks/useClientDetails";
import { Unvalidated } from "../types";
import { createAddComment, createAddMessage, deleteMessage } from "../../../actions/message";
import { useIdentity } from "../../../hooks";

const MINIMUM_NOTES_TO_FETCH = 25;

const TABS: Array<{ title: string, type: NoteType | 'all' }> = [
    { title: 'All Activity', type: 'all' },
    { title: 'Notes', type: 'NOTE' },
    { title: 'Tasks', type: 'TASK' },
    { title: 'Calls', type: 'CALL' },
    { title: 'Meetings', type: 'MEETING' },
];

const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

const Tab = styled.div<{ active?: boolean }>`
    &&& {
        font-size: 18px;
        font-weight: 600;
        padding-right: 32px;
        color: ${(props) => (props.active ? colors.neutrals["darkest"] : colors.neutrals[70])};
        cursor: pointer;
        text-decoration: ${(props) => (props.active ? "underline" : "none")};
        text-underline-offset: 1rem;
        text-decoration-color: ${colors.secondary1[50]};
        text-decoration-thickness: 8px;
    }
`;

const TabContainer = styled.div`
    &&& {
        display: flex;
        gap: 8px;
        margin-bottom: 24px;
    }
`;

const NoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActivityPage = () => {
    const { user_id } = useIdentity();
    const { client } = useClientDetails();
    const { notes, loading, error } = useSelector(getClientDetailsState);
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const dispatch = useDispatch();

    const fetchNotesWithPagination = useCallback(() => {
        if (loading || error) {
            return;
        }

        dispatch(setLoading(true));
        dispatch(fetchNotes({
            user_id,
            client_id: client.id,
            minimumNotes: MINIMUM_NOTES_TO_FETCH,
        }));
    }, [dispatch, loading, error, notes, user_id, client.id]);

    const handleScroll = useCallback(() => {
        const isFullyScrolled = Math.ceil(window.innerHeight + window.scrollY) >= document.body.scrollHeight;

        if (isFullyScrolled) {
            fetchNotesWithPagination();
        }
    }, [notes, fetchNotesWithPagination]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const onCreateNote = (newNote: Unvalidated<DisplayNote>) => {
        dispatch(createAddMessage(
            null,
            newNote.message,
            newNote.reminder?.reminderUser.id,
            newNote.reminder?.reminderDate,
            [],
            null,
            client.id,
            'CLIENT',
            null,
            newNote.type,
        ));
    }

    const onCreateComment = (newNote: Unvalidated<DisplayNote>, parent: DisplayNote) => {
        dispatch(createAddComment(
            newNote.message,
            parent.messageId,
            "message",
            user_id,
            '',
            {},
        ));
    }

    const onDeleteNote = (note: DisplayNote) => {
        dispatch(deleteMessage(note.messageId, client.id));
        dispatch(deleteNote(note));
    }

    const toggleNotePinned = (note: DisplayNote) => {
        dispatch(pinNote({
            message_id: note.messageId,
            pinned: !note.pinned
        }));
    };

    useEffect(() => {
        fetchNotesWithPagination();
    }, []);

    const selectedNotes = notes
        ?.filter((note) => activeTab.type === 'all' || note.type === activeTab.type) ?? [];

    return (
        <ActivityContainer>
            <SubPageHeader title="Activity" />
            <TabContainer>
                {TABS.map((tab) => (
                    <Tab key={tab.type} active={tab === activeTab} onClick={() => setActiveTab(tab)}>
                        {tab.title}
                    </Tab>
                ))}
            </TabContainer>
            <ActionPanel createNote={onCreateNote} />
            <NoteContainer>
                {selectedNotes.map((note) => (
                    <div key={note.id}>
                        <NoteDetails
                            note={note}
                            createComment={onCreateComment}
                            deleteNote={onDeleteNote}
                            toggleNotePinned={toggleNotePinned}
                        />
                    </div>
                ))}
            </NoteContainer>
            {loading &&
                <SpinnerContainer>
                    <SkubotSpinner />
                </SpinnerContainer>
            }
        </ActivityContainer>
    );
}
