import React, { useState } from "react";
import { DisplayNote } from "../../../types";
import { Unvalidated } from "../types";
import styled from "styled-components";
import { colors, CommentIcon, PinIcon, TrashIcon, Typography } from "@commonsku/styles";
import { CreateNoteDialog } from "./CreateNoteDialog";
import { UserAvatar } from "../UserAvatar";

const NoteContainer = styled.div<{ isComment?: boolean }>`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 16px;
        ${props => props.isComment ? 'padding-top' : 'padding-bottom'}: 8px;
    }
`;

const BorderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const BorderedNote = styled.div<{ isComment?: boolean }>`
    &&& {
        width: 100%;
        ${props => props.isComment ? 'border-top' : 'border-bottom'}: 1px solid ${colors.neutrals[40]};
    }
`;

const NoteFooter = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: row;
`;

const Actions = styled.div`
    &&& {
        margin-left: auto;
        gap: 16px;
    }
`;

const NoteText = styled(Typography.Text)`
    &&& {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: ${colors.neutrals[90]};
    }
`;

const AuthorInfoText = styled(Typography.Text)`
    &&& {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: ${colors.neutrals[70]};
    }
`;

const CommentContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-left: 32px;
    }
`;

const CreateCommentContainer = styled.div`
    &&& {
        background-color: #EDF2F4;
        border-radius: 8px;
    }
`;

const CommentButton = styled(CommentIcon)`
    &&& {
        cursor: pointer;
    }
`;

const PinButton = styled(PinIcon)`
    &&& {
        cursor: pointer;
    }
`;

const DeleteButton = styled(TrashIcon)`
    &&& {
        cursor: pointer;
    }
`;

const PinBar = styled.div<{ isPinned?: boolean }>`
    width: 5px;
    height: auto;
    background-color: ${(props) => (props.isPinned ? colors.primary1[60] : "white")};
`;

export interface NoteDetailsProps {
    note: DisplayNote
    createComment: (newNote: Unvalidated<DisplayNote>, parent: DisplayNote) => void
    deleteNote: (note: DisplayNote) => void
    toggleNotePinned: (note: DisplayNote) => void
    isComment?: boolean
}

export const NoteDetails = ({
    note,
    createComment,
    deleteNote,
    toggleNotePinned,
    isComment = false,
}: NoteDetailsProps) => {
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState(note.comments);
    const [hovering, setHovering] = useState(false);
    const mouseOver = () => {
        setHovering(true);
    }
    const mouseOut = () => {
        setHovering(false);
    }

    const toggleComments = () => {
        setShowComments(!showComments);
    }

    const onCreateComment = (newNote: Unvalidated<DisplayNote>) => {
        createComment(newNote, note);
        setComments([...comments, { id: '', messageId: '', ...newNote }]);
    }

    return (
        <BorderContainer>
            <PinBar isPinned={note.pinned} />
            <BorderedNote isComment={isComment}>
                <NoteContainer isComment={isComment}>
                    <NoteText dangerouslySetInnerHTML={{ __html: note.message }} />
                    <NoteFooter>
                        <AuthorInfo>
                            <UserAvatar user={note.author} />
                            <AuthorInfoText>
                                by {note.author.firstName} {note.author.lastName} on {note.creationDate.toLocaleDateString()}
                            </AuthorInfoText>
                        </AuthorInfo>
                        {note.noteParentType === 'CLIENT' &&
                            <Actions>
                                {!isComment &&
                                    <>
                                        <CommentButton
                                            size="medium"
                                            number={0}
                                            onClick={toggleComments}
                                        />
                                        <PinButton
                                            onMouseOver={mouseOver}
                                            onMouseOut={mouseOut}
                                            size="medium"
                                            unpin={note.pinned}
                                            filled={!note.pinned && hovering}
                                            onClick={() => toggleNotePinned(note)}
                                        />
                                    </>
                                }
                                <DeleteButton
                                    size="medium"
                                    color={colors.errors.main}
                                    onClick={() => deleteNote(note)}
                                />
                            </Actions>
                        }
                    </NoteFooter>
                    {showComments && (
                        <CommentContainer>
                            {comments.map(comment =>
                                <NoteDetails
                                    key={comment.id}
                                    note={comment}
                                    createComment={() => null}
                                    deleteNote={deleteNote}
                                    toggleNotePinned={() => null}
                                    isComment
                                />
                            )}
                            <CreateCommentContainer>
                                <CreateNoteDialog type="COMMENT" createNote={onCreateComment} />
                            </CreateCommentContainer>
                        </CommentContainer>
                    )}
                </NoteContainer>
            </BorderedNote>
        </BorderContainer>
    );
}
