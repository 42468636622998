import React from "react";
import { colors, Dropdown, Text } from "@commonsku/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setEditingDetails, showPopup } from "../../../redux/clientDetails";
import { getIdentityUtils, oauth } from "../../../utils";
import { toast } from "react-toastify";
import { useIdentity } from "../../../hooks";
import useClientDetails from "../hooks/useClientDetails";
import { createUpdateClient } from "../../../actions/client";

const ActionText = styled(Text) <{ $error?: boolean; }>`
    &&& {
        font-size: 14px;
        color: ${({ $error }) => $error ? colors.errors[50] : colors.neutrals[90]};
    }
`;

interface ActionItem {
    content: React.ReactNode;
    onClick?: () => void;
    isVisible: boolean;
}

export const ActionsMenu = () => {
    const { client } = useClientDetails();
    const dispatch = useDispatch();
    const identity = useIdentity();
    const { isAdmin, hasCapabilities, hasUserFlags } = getIdentityUtils(identity);

    const toggleCreditHold = () => {
        oauth('PUT', `client/${client.id}`, {
            credit_hold: !client.creditHold,
        }).then(() => {
            dispatch(
                createUpdateClient(
                    client.id,
                    { credit_hold: !client.creditHold, update_remote: true }
                )
            );
            toast.success(`Credit hold ${client.creditHold ? 'removed' : 'applied'}`);
        });
    };

    const actions: ActionItem[] = [
        {
            // Can't use react-router navigation outside of react
            content: <a
                href={`/client.php?id=${client.id}&force_old=1`}
                rel="opener noreferrer"
                target="_blank"
            >
                <ActionText>View Old Client Page</ActionText>
            </a>,
            isVisible: true,
        },
        {
            content: <ActionText>New Project</ActionText>,
            onClick: () => dispatch(showPopup("create-project")),
            isVisible: isAdmin() || hasUserFlags(['CREATE-PROJECT']),
        },
        {
            content: <ActionText>New Contact</ActionText>,
            onClick: () => dispatch(showPopup("create-contact")),
            isVisible: true,
        },
        {
            content: <ActionText>New Address</ActionText>,
            onClick: () => dispatch(showPopup("create-address")),
            isVisible: true,
        },
        {
            content: <ActionText>Edit Details</ActionText>,
            onClick: () => {
                dispatch(setEditingDetails(true));
                dispatch(showPopup("view-details"));
            },
            isVisible: isAdmin() || hasCapabilities(
                ["MODIFY-DOWNSTREAM-ACCOUNT", "CREATE-DOWNSTREAM-ACCOUNT", 'MODIFY-CLIENT']
                , true),
        },
        {
            // Can't use react-router navigation outside of react
            content: <a
                href={`/send_email.php?parent_type=CLIENT&parent_id=${client.id}&company_type=CLIENT`}
                rel="opener noreferrer"
                target="_blank"
            >
                <ActionText>Send Email</ActionText>
            </a>,
            isVisible: true,
        },
        {
            content: <ActionText>
                {client.creditHold ? 'Remove' : 'Apply'} Credit Hold
            </ActionText>,
            onClick: toggleCreditHold,
            isVisible: isAdmin() || hasCapabilities(['MODIFY-CREDIT-HOLD']),
        },
        {
            content: <Link
                to={"./portal/"}
                rel="opener noreferrer"
                target="_blank"
            >
                <ActionText>View Client Portal</ActionText>
            </Link>,
            isVisible: true,
        },
        {
            content: <ActionText $error>Delete Client</ActionText>,
            onClick: () => dispatch(showPopup("delete-client")),
            isVisible: isAdmin() || hasCapabilities(['DELETE-DOWNSTREAM-ACCOUNT']),
        },
    ];

    return (
        <Dropdown
            items={actions.filter(item => item.isVisible)}
            width={170}
        />
    );
};
