import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  adaptUser,
  adaptTax,
  adaptDepartment,
  adaptTag,
  adaptPhone,
  adaptAddress,
  adaptContact,
  adaptAccountStatus,
  adaptTerms,
  adaptCurrency,
  adaptClientPortal,
  adaptClient,
  adaptIndustry,
  getClientDetailsState,
  adaptCourier,
  adaptShippingAccount,
} from "../../../redux/clientDetails";

import {
  Currency,
  DisplayAddress,
  DisplayClient,
  DisplayContact,
  DisplayCourier,
  DisplayPhone,
  DisplayShippingAccount,
  DisplayUser,
} from '../../../types';

interface UseClientDetailsResult {
  client: DisplayClient;
  contacts: DisplayContact[];
  currencies: Currency[];
  addresses: DisplayAddress[];
  couriers: DisplayCourier[];
  shippingAccounts: DisplayShippingAccount[];
  phones: DisplayPhone[];
  users: DisplayUser[];
}

const useClientDetails: () => UseClientDetailsResult = () => {
  const baseUsers = useSelector((s: any) => s.entities.users);
  const baseTaxes = useSelector((s: any) => s.entities.taxes);
  const baseAddresses = useSelector((s: any) => s.entities.addresses);
  const baseCouriers = useSelector((s: any) => s.entities.couriers);
  const baseShippingAccounts = useSelector((s: any) => s.entities.third_party_shipping_accounts);
  const baseDepartments = useSelector((s: any) => s.entities.departments);
  const baseTags = useSelector((s: any) => s.entities.tags);
  const basePhones = useSelector((s: any) => s.entities.phones);
  const baseAccountStatuses = useSelector((s: any) => s.entities.account_statuses);
  const baseTerms = useSelector((s: any) => s.entities.terms);
  const baseCurrencies = useSelector((s: any) => s.entities.currencies);
  const baseClientPortals = useSelector((s: any) => s.entities.client_portals);
  const baseIndustries = useSelector((s: any) => s.entities.industries);
  const baseContacts = useSelector((s: any) => s.entities.contacts);
  const baseClients = useSelector((s: any) => s.entities.clients);
  const { loading } = useSelector(getClientDetailsState);

  const users = useMemo(
    () => Object.values(baseUsers).map(adaptUser),
    [baseUsers]
  );

  const taxes = useMemo(
    () => Object.values(baseTaxes).map(adaptTax),
    [baseTaxes]
  );

  const addresses = useMemo(
    () => Object.values(baseAddresses).map(adaptAddress),
    [baseAddresses]
  );


  const couriers = useMemo(
    () => Object.values(baseCouriers).map(adaptCourier),
    [baseCouriers]
  );

  const shippingAccounts = useMemo(
    () => Object.values(baseShippingAccounts).map(
      shippingAccount => adaptShippingAccount(shippingAccount, couriers)
    ),
    [baseShippingAccounts, couriers]
  );

  const departments = useMemo(
    () => Object.values(baseDepartments).map(adaptDepartment),
    [baseDepartments]
  );

  const tags = useMemo(
    () => Object.values(baseTags).map(adaptTag),
    [baseTags]
  );

  const phones = useMemo(
    () => Object.values(basePhones).map(adaptPhone),
    [basePhones]
  );

  const accountStatuses = useMemo(
    () => Object.values(baseAccountStatuses).map(adaptAccountStatus),
    [baseAccountStatuses]
  );

  const terms = useMemo(
    () => Object.values(baseTerms).map(adaptTerms),
    [baseTerms]
  );

  const currencies = useMemo(
    () => Object.values(baseCurrencies).map(adaptCurrency),
    [baseCurrencies]
  );

  const clientPortals = useMemo(
    () => Object.values(baseClientPortals).map(adaptClientPortal),
    [baseClientPortals]
  );

  const industries = useMemo(
    () => Object.values(baseIndustries).map(adaptIndustry),
    [baseIndustries]
  );

  const contacts = useMemo(
    () => Object.values(baseContacts).map(
      contact => adaptContact(contact, departments, addresses, tags, phones)
    ),
    [baseContacts, departments, addresses, tags, phones]
  );

  const client = useMemo(
    () => adaptClient(
      Object.values(baseClients)[0],
      users,
      contacts,
      accountStatuses,
      terms,
      taxes,
      currencies,
      tags,
      clientPortals,
      industries,
    ),
    [
      baseClients,
      users,
      contacts,
      accountStatuses,
      terms,
      taxes,
      currencies,
      tags,
      clientPortals,
      industries,
      loading,
    ]
  );

  return {
    client,
    contacts,
    currencies,
    addresses,
    couriers,
    shippingAccounts,
    phones,
    users,
  };
};

export default useClientDetails;
