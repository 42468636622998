import React, { useMemo } from "react";
import styled from "styled-components";
import useClientDetails from "../hooks/useClientDetails";
import { ReportConfig, ReportElementSizes, getReportConfig } from "../../report/config";
import { EReportTypes } from "../../report/routes/report_types";
import { ReportProvider } from "../../report/report_context";
import { Csku, Text, colors } from "@commonsku/styles";
import ReportTable from "../../report/table/ReportTable";
import Pagination from "../../report/table/Pagination";
import Loading from "../../report/Loading";
import ReportFiltersContainer from "../../report/filters/ReportFiltersContainer";
import { NewProjectPopupButton } from "../../project/NewProjectPopup";

const DEFAULT_STYLE_CONSTANTS: ReportElementSizes = {
    SIDE_BAR_WIDTH: 192,
    PREFERRED_WINDOW_WIDTH: 1512,
    FILTER_CONTAINER_HEIGHT: 0, // Filter is rendered elsewhere
    TABLE_ELEMENTS_GAP: 16,
    SUMMARY_CONTAINER_HEIGHT: 0, // No summary
    TABLE_PAGE_HEADER_HEIGHT: 152, // Header 72 + Subtitle 80
    ROW_HEIGHT: 80,
    TABLE_BOTTOM_MARGIN: 112,
    BULK_ACTION_BAR_HEIGHT: 69,
} as const;

const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const Header = styled.div`
    padding: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-height: 40px;
    &&& {
        margin-bottom: 16px;
    }
`;

const HeaderText = styled(Text)`
    flex: 1;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    cursor: default;
    color: ${colors.neutrals[100]};
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NewProjectButton = styled(NewProjectPopupButton)`
    white-space: nowrap;
    margin-left: 8px !important;
`; 

const ReportFilters = styled(ReportFiltersContainer)`
    margin-bottom: 0 !important;
    margin-top: 6px !important;
`;

export const ProjectsPage = () => {
    const { client } = useClientDetails();

    const reportConfig: ReportConfig = useMemo(
        () => ({ ...getReportConfig(EReportTypes.ClientProject) }),
        []
    );

    const defaultFilters = useMemo(
        () => ({
            client_id: client.id,
        }),
        [client.id]
    );

    return (
        <ReportProvider
            reportConfig={reportConfig}
            defaultFilters={defaultFilters}
            elementSizeParameters={DEFAULT_STYLE_CONSTANTS}
        >
            <ProjectContainer>
                <Header>
                    <HeaderText>Projects</HeaderText>
                    <HeaderActions>
                        <ReportFilters />
                        <NewProjectButton style={{}} isButton buttonProps={{size: 'medium'}}/>
                    </HeaderActions>
                </Header>
                <Csku as={"div"}>
                    <ReportTable
                        headerClassName="client-project-report-table-header subreport-table-header"
                    />
                    <Pagination />
                    <Loading />
                </Csku>
            </ProjectContainer>
        </ReportProvider>
    );
};
