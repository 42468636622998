import { useDispatch } from "react-redux";
import { DisplayClient, DisplayContact } from "../../../types";
import React, { useCallback, useState } from "react";
import { EditContactFields } from "../../contact/EditContactForm";
import { isString } from "../../../types/type_guards";
import { toast } from "react-toastify";
import { createAddContactPhone, createDeleteContactPhone, updateContactTags, createUpdateFullContact } from "../../../actions/contact";
import useClientDetails from "./useClientDetails";
import { EditContactPopup } from "../popups/EditContactPopup";

const CONTACT_FIELDS_MAP: { [k in keyof EditContactFields]?: keyof DisplayContact } = {
    contact_first_name: "firstName",
    contact_last_name: "lastName",
    contact_position: "position",
    contact_email: "email",
    contact_twitter: "twitter",
    contact_linkedin: "linkedin",
    contact_facebook: "facebook",
    contact_skype: "skype",
    contact_no_marketing: "noMarketing",
} as const;

const CONTACT_UPDATE_FIELDS = [
    'contact_first_name',
    'contact_last_name',
    'contact_email',
    'contact_position',
    'contact_department_id',
    'contact_twitter',
    'contact_linkedin',
    'contact_facebook',
    'contact_skype',
    'contact_default_address_id',
    'contact_no_marketing',
];

type UpdateContactFields = EditContactFields & {
    phone: Array<{
        phone_type: string;
        phone_number: string;
        phone_extension: string;
    }>
};

export const useEditContact = (contact: DisplayContact) => {
    const { client } = useClientDetails();
    const dispatch = useDispatch();

    const onUpdateContact = useCallback((updatedFields: EditContactFields) => {
        if (!contact) {
            return;
        }

        const updatedValue = CONTACT_UPDATE_FIELDS.reduce(
            (o, k) => contact[CONTACT_FIELDS_MAP[k]] !== updatedFields[k] ? {
                ...o,
                [k]: updatedFields[k]
            } : o,
            {} as UpdateContactFields
        );
        const previousValue = Object.keys(CONTACT_FIELDS_MAP).reduce(
            (o, k) => ({ ...o, [k]: contact[CONTACT_FIELDS_MAP[k]] }),
            {}
        );

        try {
            const newPhones = updatedFields.phones
                .filter(phone => phone.phone_number.length > 0 || phone.phone_extension.length > 0)
                .map(phone => {
                    if (
                        isString(phone.phone_number) && 
                        phone.phone_number.length > 0 &&
                        isString(phone.phone_extension) &&
                        phone.phone_extension.length > 0
                    ) {
                        return `${phone.phone_number} ext. ${phone.phone_extension}`;
                    }
                    else if (isString(phone.phone_number)) {
                        return phone.phone_number;
                    }
                    else {
                        return phone.phone_extension;
                    }
                });

            // Create new phones
            const deltaPhones = [];

            newPhones.forEach((phoneNumber, index) => {
                const phoneExists = contact.phones.some(oldPhone => (
                    oldPhone.number === phoneNumber &&
                    oldPhone.type === updatedFields.phones[index].phone_type.toUpperCase()
                ));

                if (!phoneExists) {
                    deltaPhones.push(updatedFields.phones[index]);
                }
            });

            deltaPhones.forEach(phone => {
                dispatch(createAddContactPhone(
                    contact.id,
                    client.id, 
                    [],
                    phone.phone_type,
                    phone.phone_number,
                    phone.phone_extension,
                ));
            });

            // Delete phones that were removed
            contact.phones.forEach(phone => {
                const phoneExists = newPhones.some((newPhoneNumber, index) => 
                    phone.number === newPhoneNumber &&
                    phone.type === updatedFields.phones[index].phone_type.toUpperCase()
                );

                if (phoneExists) {
                    return;
                }

                dispatch(createDeleteContactPhone(contact.id, phone.id));
            });

            dispatch(updateContactTags(contact.id, client.id, updatedFields.contact_tags.join(',')));   
            const oldAddressId = contact.address?.id ?? null;
            if (updatedFields.contact_default_address_id && (!oldAddressId || oldAddressId !== updatedFields.contact_default_address_id)) {

                updatedValue.contact_default_address_id = updatedFields.contact_default_address_id
            }

            dispatch(createUpdateFullContact(contact.id, client.id, previousValue, updatedValue));
            toast.success("Contact updated successfully");
        } catch (e) {
            toast.error("Error updating contact");
        }
    }, [client.id, contact]);

    return {
        onUpdateContact
    };
}
