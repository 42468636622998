import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ClientPortal,
    Currency,
    DisplayAddress,
    DisplayClient,
    DisplayContact,
    DisplayCourier,
    DisplayDepartment,
    DisplayNote,
    DisplayPhone,
    DisplayShippingAccount,
    DisplayTag,
    DisplayTax,
    DisplayTerms,
    DisplayUser,
    isClientPortal,
    isCurrency,
    isDisplayAddress,
    isDisplayClient,
    isDisplayContact,
    isDisplayCourier,
    isDisplayDepartment,
    isDisplayNote,
    isDisplayPhone,
    isDisplayShippingAccount,
    isDisplayTag,
    isDisplayTax,
    isDisplayTerms,
    isDisplayUser,
    validateClientPortal,
    validateCurrency,
    validateDisplayAddress,
    validateDisplayClient,
    validateDisplayContact,
    validateDisplayCourier,
    validateDisplayDepartment,
    validateDisplayNote,
    validateDisplayPhone,
    validateDisplayShippingAccount,
    validateDisplayTag,
    validateDisplayTax,
    validateDisplayTerms,
    validateDisplayUser,
} from '../types';
import { DisplayAccountStatus, isDisplayAccountStatus, validateDisplayAccountStatus } from "../types/account_status";
import { DisplayIndustry, isDisplayIndustry } from "../types/industry";
import { popups } from "../components/client-page/types";
import { isNumber, isString } from "../types/type_guards";
import { oauth } from "../utils";
import { ADD_MESSAGE_SUCCESS } from "../actions/message";

declare global {
    interface Window {
        initialState: unknown;
    }
}

export interface ClientDetailsState {
    loading: boolean;
    currentPopup: keyof typeof popups | null;
    editingDetails: boolean;
    notes: readonly DisplayNote[];
    error?: string;
    oldestNoteLoaded: boolean;
} 

export const adaptUser = (user: any): DisplayUser => {
    const adaptedUser = {
        id: user.user_id,
        firstName: user.user_first_name ?? undefined,
        lastName: user.user_last_name ?? undefined,
        avatarImagePath: user.user_avatar_image_path?.length > 0
            ? new URL(user.user_avatar_image_path)
            : undefined,
    };

    if (!isDisplayUser(adaptedUser)) {
        throw new Error("Did not receive a valid DisplayUser");
    }

    validateDisplayUser(adaptedUser);
    return adaptedUser;
};

export const adaptTax = (tax: any): DisplayTax => {
    const adaptedTax = {
        id: tax.tax_id,
        label: tax.label,
        percent: parseFloat(tax.percent),
    };
    if (!isDisplayTax(adaptedTax)) {
        throw new Error("Did not receive a valid DisplayTax");
    }

    validateDisplayTax(adaptedTax);
    return adaptedTax;
};

export const adaptDepartment = (department: any): DisplayDepartment => {
    const adaptedDepartment = {
        id: department.department_id,
        name: department.department_name,
        ordering: Number(department.ordering),
    };

    if (!isDisplayDepartment(adaptedDepartment)) {
        throw new Error("Did not receive a valid DisplayDepartment");
    }

    validateDisplayDepartment(adaptedDepartment);
    return adaptedDepartment;
};

export const adaptTag = (tag: any): DisplayTag => {
    const adaptedTag = {
        id: tag.tag_id,
        label: tag.label,
    };
    
    if (!isDisplayTag(adaptedTag)) {
        throw new Error("Did not receive a valid DisplayTag");
    }

    validateDisplayTag(adaptedTag);
    return adaptedTag;
};

export const adaptPhone = (phone: any): DisplayPhone => {
    let number: string;

    if (
        isString(phone.phone_number) && 
        phone.phone_number.length > 0 &&
        isString(phone.phone_extension) &&
        phone.phone_extension.length > 0
    ) {
        number = `${phone.phone_number} ext. ${phone.phone_extension}`;
    }
    else if (isString(phone.phone_number)) {
        number = phone.phone_number;
    }
    else {
        number = phone.phone_extension;
    }

    const adaptedPhone = {
        id: phone.phone_id,
        type: phone.phone_type,
        number: number,
    }

    if (!isDisplayPhone(adaptedPhone)) {
        throw new Error("Did not receive a valid DisplayPhone");
    }

    validateDisplayPhone(adaptedPhone);
    return adaptedPhone;
};

export const adaptAddress = (address: any): DisplayAddress => {
    const adaptedAddress = {
        id: address.address_id,
        type: address.address_type,
        name: address.address_name ?? undefined,
        city: address.address_city ?? '',
        state: address.address_state ?? '',
        country: address.address_country ?? '',
        postal: address.address_postal ?? '',
        address_line_1: address.address_line_1 ?? '',
        address_line_2: address.address_line_2 ?? '',
        address_line_3: address.address_line_3 ?? '',
        address_line_4: address.address_line_4 ?? '',
        active: !!address.active,
    };

    if (!isDisplayAddress(adaptedAddress)) {
        throw new Error("Did not receive a valid DisplayAddress");
    }

    validateDisplayAddress(adaptedAddress);
    return adaptedAddress;
};

export const adaptCourier = (courier: any): DisplayCourier => {
    const adaptedCourier = {
        id: courier.courier_id,
        name: courier.courier_name,
        label: courier.courier_label,
        active: !!courier.active,
    };
    
    if (!isDisplayCourier(adaptedCourier)) {
        throw new Error("Did not receive a valid DisplayCourier");
    }

    validateDisplayCourier(adaptedCourier);
    return adaptedCourier;
}

export const adaptShippingAccount = (shippingAccount: any, couriers: DisplayCourier[]): DisplayShippingAccount => {
    const adaptedCourier = couriers.find(courier => courier.id === shippingAccount.courier_id);

    if (adaptedCourier === undefined) {
        throw new Error(`Could not find courier with ID ${shippingAccount.courier_id}`);
    }

    const adaptedShippingAccount = {
        id: shippingAccount.third_party_account_id,
        name: shippingAccount.account_name,
        number: shippingAccount.account_number,
        postal: shippingAccount.account_postal,
        active: !!shippingAccount.active,
        courier: adaptedCourier,
    };

    if (!isDisplayShippingAccount(adaptedShippingAccount)) {
        throw new Error("Did not receive a valid DisplayShippingAccount");
    }

    validateDisplayShippingAccount(adaptedShippingAccount);
    return adaptedShippingAccount;
}

export const adaptContact = (
    contact: any,
    departments: DisplayDepartment[],
    addresses: DisplayAddress[],
    tags: DisplayTag[],
    phones: DisplayPhone[],
): DisplayContact => {
    const defaultDepartment: DisplayDepartment = {
        id: '0',
        name: 'Unknown',
        ordering: Infinity,
    };

    const adaptedPhoneIds = (contact.phones ?? []).length > 0 && typeof contact.phones[0] === 'object'
        ? contact.phones.map(phone => phone.phone_id)
        : (contact.phones ?? []);

    const adaptedContact = {
        id: contact.contact_id ?? contact.company_contact_id,
        firstName: contact.contact_first_name,
        lastName: contact.contact_last_name,
        email: contact.contact_email ?? '',
        position: contact.contact_position ?? '',
        department: departments.find(department => department.id === (contact.contact_department_id ?? contact.department_id))
            ?? defaultDepartment,
        noMarketing: !!contact.contact_no_marketing,
        twitter: contact.contact_twitter ?? undefined,
        facebook: contact.contact_facebook ?? undefined,
        linkedin: contact.contact_linkedin ?? undefined,
        skype: contact.contact_skype ?? undefined,
        address: (contact.contact_address_id ?? contact.contact_default_address_id)
            ? addresses.find(address => address.id === (contact.contact_address_id ?? contact.contact_default_address_id))
            : undefined,
        tags: (contact.tags ?? []).map(id => tags.find(tag => tag.id === id)).filter(tag => tag !== undefined),
        phones: adaptedPhoneIds.map(id => phones.find(phone => phone.id === id)),
        hubspotSyncStatus: contact.hubspot_sync_status
            ? contact.hubspot_sync_status
            : undefined,
        active: !!contact.active,
    };

    if (!isDisplayContact(adaptedContact)) {
        throw new Error(`Did not receive a valid DisplayContact [${contact.contact_id ?? contact.company_contact_id}]`);
    }

    validateDisplayContact(adaptedContact);
    return adaptedContact;
};

export const adaptAccountStatus = (accountStatus: any): DisplayAccountStatus => {
    const adaptedAccountStatus = {
        id: accountStatus.account_status_id,
        name: accountStatus.account_status_name,
    };

    if (!isDisplayAccountStatus(adaptedAccountStatus)) {
        throw new Error("Did not receive a valid DisplayAccountStatus");
    }

    validateDisplayAccountStatus(adaptedAccountStatus);
    return adaptedAccountStatus;
};

export const adaptTerms = (terms: any): DisplayTerms => {
    const adaptedTerms = {
        id: terms.terms_id,
        name: terms.terms_name,
    };

    if (!isDisplayTerms(adaptedTerms)) {
        throw new Error("Did not receive a valid DisplayTerms");
    }

    validateDisplayTerms(adaptedTerms);
    return adaptedTerms;
};

export const adaptCurrency = (currency: any): Currency => {
    const adaptedCurrency = {
        id: currency.currency_id,
        country: currency.currency_country,
        name: currency.currency_name,
        symbol: currency.currency_symbol,
    };

    if (!isCurrency(adaptedCurrency)) {
        throw new Error("Did not receive a valid Currency");
    }

    validateCurrency(adaptedCurrency);
    return adaptedCurrency;
};

export const adaptClientPortal = (clientPortal: any): ClientPortal => {
    const adaptedPortal = {
        id: clientPortal.client_portal_id,
    }

    if (!isClientPortal(adaptedPortal)) {
        throw new Error("Did not receive a valid ClientPortal");
    }

    validateClientPortal(adaptedPortal);
    return adaptedPortal;
};

export const adaptClient = (
    client: any,
    users: DisplayUser[],
    contacts: DisplayContact[],
    accountStatuses: DisplayAccountStatus[],
    terms: DisplayTerms[],
    taxes: DisplayTax[],
    currencies: Currency[],
    tags: DisplayTag[],
    portals: ClientPortal[],
    industries: DisplayIndustry[],
): DisplayClient => {
    const adaptedClient = {
        id: client.client_id,
        name: client.client_name,
        industry: industries
            .find(industry => industry.id === client.industry_id),
        tenantAccountNumber: isString(client.client_tenant_account_number) && client.client_tenant_account_number.length > 0
            ? client.client_tenant_account_number
            : null,
        clientRep: users.find(user => user.id === client.sales_rep_id) ?? null,
        primaryContact: contacts.find(contact => contact.id === client.primary_contact_id) ?? null,
        status: client.account_status_id !== null
            ? accountStatuses.find(status => status.id === client.account_status_id)
            : null,
        defaultTerms: terms
            .find(term => term.id === client.default_terms_id),
        defaultTax: taxes
            .find(tax => tax.id === client.default_tax_id),
        defaultCurrency: currencies
            .find(currency => currency.id === client.default_currency_id),
        tags: (client.tags ?? [])
            .map(id => tags.find(tag => tag.id === id)),
        minMargin: client.client_order_margin_minimum !== null
            ? Number(client.client_order_margin_minimum)
            : null,
        portal: client.client_portal_id !== null
            ? portals.find(portal => portal.id === client.client_portal_id)
            : null,
        salesToDate: 0, // TODO how is this calculated?
        salesTarget: Number(client.sales_target),
        avalaraEntityUseCode: isString(client.avalara_entity_use_code) && client.avalara_entity_use_code.length > 0
            ? client.avalara_entity_use_code
            : null,
        creditHold: !!client.credit_hold,
        parentClientId: isString(client.parent_client_id) && client.parent_client_id.length > 0
            ? client.parent_client_id
            : null,
        qboCustomerRef: isNumber(client.qbo_customer_ref)
            ? client.qbo_customer_ref
            : null,
        profile: isString(client.profile)
            ? client.profile
            : null,
        website: isString(client.client_website)
            ? client.client_website
            : null,
    };

    if (!isDisplayClient(adaptedClient)) {
        throw new Error("Did not receive a valid DisplayClient");
    }

    validateDisplayClient(adaptedClient);
    return adaptedClient;
};

export const adaptIndustry = (industry: any): DisplayIndustry => {
    const adaptedIndustry = {
        id: industry.industry_id,
	    name: industry.industry_name,
    };

    if (!isDisplayIndustry(adaptedIndustry)) {
        throw new Error("Did not receive a valid DisplayIndustry");
    }

    return adaptedIndustry;
};

const adaptNote = (note: any, users: DisplayUser[]): DisplayNote => {   
    const authorId = note.actor.user_id;

    const comments = note.replies
        ? note.replies.map((comment: any) => adaptNote(comment, users))
        : [];

    const adaptedNote = {
        type: (note.note_type ?? note.note?.note_type) ?? 'NOTE',
        parentType: note.parent_type?.toUpperCase() ?? undefined,
        noteParentType: (note.note?.parent_type ?? note.details_parent_type) ?? undefined,
        id: note.note_id ?? note.message_id,
        messageId: note.message_id,
        message: note.text ?? note.message_text,
        author: users.find(user => user.id === authorId),
        creationDate: new Date(note.date * 1000),
        pinned: note.pinned ? true : false,
        comments: comments,
        contact: undefined,
        reminder: undefined,
    }

    if (!isDisplayNote) {
        throw new Error("Did not receive a valid DisplayNote");
    }

    validateDisplayNote(adaptedNote);
    return adaptedNote;
}

const getInitialState = (): ClientDetailsState => {
    return {
        loading: false,
        currentPopup: null,
        editingDetails: false,
        notes: [],
        oldestNoteLoaded: false,
    };
};

export const fetchNotes = createAsyncThunk<
    {
        notes: DisplayNote[],
        oldestNoteLoaded: boolean,
    },
    {
        client_id: string,
        user_id: string,
        minimumNotes?: number,
        getNewNotes?: boolean,
    },
    {
        state: {
            clientDetails: ClientDetailsState,
        },
    }
>(
    'clientDetails/fetchNotes',
    async ({ client_id, user_id, minimumNotes = 1, getNewNotes = false }, { getState }) => {
        const notes = [];

        const {
            clientDetails: {
                notes: existingNotes,
                oldestNoteLoaded,
            },
        } = getState();

        if (oldestNoteLoaded && !getNewNotes) {
            return {
                notes,
                oldestNoteLoaded,
            };
        }

        const data = {
            user_id,
            child_id: client_id,
            type: "CLIENT",
            show_all: true,
            date_range: 'all',
            filter: 'all',
        };

        if (getNewNotes && existingNotes.length > 0) {
            data['start_date'] = existingNotes[0].creationDate.getTime() / 1000 + 1;
        } else if (existingNotes.length > 0) {
            data['since'] = existingNotes[existingNotes.length - 1].creationDate.getTime() / 1000;
        }

        let notesAdded = -1;

        while (notes.length < minimumNotes && notesAdded !== 0) {
            const { json: { newsfeed } } = await oauth('GET', 'newsfeed/client', data);

            const newsfeedUsers: Record<string, DisplayUser> = {};

            newsfeed
                .reduce((actors: any[], note: any) => {
                    actors.push(note.actor);
                    actors.push(...note.replies.map((reply: any) => reply.actor));
                    return actors;
                }, [])
                .forEach((actor: any) => {
                    if (actor.user_id in newsfeedUsers) return;

                    newsfeedUsers[actor.user_id] = {
                        id: actor.user_id,
                        firstName: actor.user_first_name,
                        lastName: actor.user_last_name,
                        avatarImagePath: actor.user_image,
                    };
                });

            const newNotes = newsfeed.map(
                (note: any) => adaptNote(note, Object.values(newsfeedUsers))
            );

            notesAdded = newNotes.length;
            notes.push(...newNotes);

            if (newNotes.length > 0) {
                data['since'] = newNotes[newNotes.length - 1].creationDate.getTime() / 1000;
            }
        }

        return {
            notes,
            oldestNoteLoaded: (!getNewNotes)
                ? notesAdded === 0 
                : oldestNoteLoaded,
        };
    },
);

export const pinNote = createAsyncThunk(
    'clientDetails/pinNote',
    async ({ message_id, pinned }: { message_id: string, pinned: boolean }) => {
        await oauth('PUT', `message/${message_id}`, {
            pinned: pinned ? 1 : 0,
        });

        return { message_id, pinned };
    }
);

const sortNotes = (notes) => {
    return [...notes].sort((a, b) => {
        if (b.pinned && !a.pinned) return 1;
        if (a.pinned && !b.pinned) return -1;
        return b.creationDate.getTime() - a.creationDate.getTime();
    });
};

const clientDetailsSlice = createSlice({
    name: "clientDetails",
    initialState: getInitialState() satisfies ClientDetailsState as ClientDetailsState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        showPopup(state, action: PayloadAction<keyof typeof popups>) {
            state.currentPopup = action.payload;
        },
        closePopup(state) {
            state.currentPopup = null;
            state.editingDetails = false;
        },
        setEditingDetails(state, action: PayloadAction<boolean>) {
            state.editingDetails = action.payload;
        },
        deleteNote(state, action: PayloadAction<DisplayNote>) {
            const noteIndex = state.notes?.findIndex(note => note.id === action.payload.id);

            if (noteIndex !== undefined && noteIndex >= 0) {
                state.notes?.splice(noteIndex, 1);
                return;
            }

            state.notes.forEach(note => {
                const commentIndex = note.comments.findIndex(comment => comment.id === action.payload.id);

                if (commentIndex !== undefined && commentIndex >= 0) {
                    note.comments.splice(commentIndex, 1);
                    return;
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotes.fulfilled, (state, action) => {
            action.payload.notes.forEach(note => {
                const existingNote = state.notes?.find(existingNote => existingNote.id === note.id);

                if (existingNote) {
                    // @ts-ignore
                    existingNote.comments = [...note.comments];
                    return;
                }

                // @ts-ignore
                state.notes.push(note);
            });
            state.notes = sortNotes(state.notes);
            state.loading = false;
            state.oldestNoteLoaded = action.payload.oldestNoteLoaded;
        });

        builder.addCase(pinNote.fulfilled, (state, action) => {
            const { message_id, pinned } = action.payload;
            const note = state.notes?.find(note => note.messageId === message_id);

            if (note) {
                note.pinned = pinned;
                state.notes = sortNotes(state.notes);
            }
        });

        builder.addCase(ADD_MESSAGE_SUCCESS, (state, action: any) => {
            const noteData = {
                ...action.payload.message,
                actor: {
                    user_id: action.payload.message.user_id,
                    user_first_name: action.payload.message.user_first_name,
                    user_last_name: action.payload.message.user_last_name,
                    user_image: action.payload.message.user_image,
                }
            };

            const author: DisplayUser = {
                id: noteData.actor.user_id,
                firstName: noteData.actor.user_first_name,
                lastName: noteData.actor.user_last_name,
                avatarImagePath: noteData.actor.user_image,
            };

            const note = adaptNote(noteData, [author]);

            // @ts-ignore
            state.notes.push(note);
            state.notes = sortNotes(state.notes);
        });
    }
});

export const getClientDetailsState =
    (state): ClientDetailsState => state.clientDetails;

export const {
    setLoading,
    showPopup,
    closePopup,
    setEditingDetails,
    deleteNote,
} = clientDetailsSlice.actions;

export default clientDetailsSlice.reducer;
